<template>
    <div class="px-3">
        <MainBanner ></MainBanner>

        <ul class="pt-0 d-flex text-center fw-400">
            <li class="rounded-start py-3 w-50"
                :class="{'bg-yellow-1 fw-bold': $route.name=='Notice' , 'bg-yellow-4 text-gray-500' : $route.name !='Notice'}"
                @click="$router.push('/notice')"
            >NOTICE</li>

            <li class="rounded-end py-3 w-50" 
                :class="{'bg-yellow-1 fw-bold': $route.name=='Event' , 'bg-yellow-4 text-gray-500' : $route.name !='Event'}"
                @click="$router.push('/event')">EVENT</li>
        </ul>
    </div>
</template>
<script>
import MainBanner from '@/components/board/MainBanner.vue'
export default {
    components : {
        MainBanner
    },
    mounted(){
    }
}
</script>